import React, { FunctionComponent, ReactNode } from "react"
import MqDefaultPhoto from "../../../static/invest/youtube-image/why-invest/mqdefault.png"
import HqDefaultPhoto from "../../../static/invest/youtube-image/why-invest/hqdefault.png"
import SdDefaultPhoto from "../../../static/invest/youtube-image/why-invest/sddefault.png"
import MaxResolutionPhoto from "../../../static/invest/youtube-image/why-invest/maxresdefault.png"

/** svg */
import ListenApplePodcasts from "../../../assets/listen-apple-podcasts.svg"
import ListenGooglePodcasts from "../../../assets/listen-google-podcasts.svg"
import ListenIheartRadioPodcasts from "../../../assets/listen-iheart-radio.svg"
import ListenPandoraPodcasts from "../../../assets/listen-pandora.svg"
import ListenSpotifyPodcasts from "../../../assets/listen-spotify.svg"
import ListenAmazonPodcasts from "../../../assets/listen-amazon.svg"
import ListenSticherPodcasts from "../../../assets/listen-stitcher.svg"
import ListenPodcastAddictPodcasts from "../../../assets/listen-podcast-addict.svg"
import ListenOvercastPodcasts from "../../../assets/listen-overcast.svg"
import ListenPocketCastsPodcasts from "../../../assets/listen-pocket-casts.svg"
import ListenPodcastIndexPodcasts from "../../../assets/listen-podcast-index.svg"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  icon: any
  iconTitle: string
  title: string
  subTitle?: string
  formatSubTitle?: () => ReactNode
  image: string
  imageAlt: string
  textColor: string
  buttonName?: string
  buttonUrl?: string
  starRating?: string
  subNote?: any
  asoEffect: string
  posterFrame: string
}

/** const */
const HeroBottomInvestLandingWhyInvestSection: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  icon: Icon,
  iconTitle,
  title,
  subTitle,
  formatSubTitle,
  image,
  imageAlt,
  textColor,
  buttonName,
  buttonUrl,
  starRating,
  subNote,
  asoEffect,
}) => {
  const renderedSubTitle = subTitle ?? formatSubTitle()

  const youtubeReference = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style>
  <a href=https://www.youtube.com/embed/k9hoBkeJLQc>
    <picture>
      <source media="(max-width:320px)" srcset=${MqDefaultPhoto} style="object-fit:contain">
      <source media="(max-width:480px)" srcset=${HqDefaultPhoto} style="object-fit:contain">
      <source media="(max-width:640px)" srcset=${SdDefaultPhoto}  style="object-fit:contain">
      <img src=${MaxResolutionPhoto} alt="Why Norhart Invest" style="object-fit:contain">
    </picture>
    <span>▶
    </span>
  </a>`

  return (
    <section
      className="container-fluid text-center pt-5 pb-5"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <a id="NavVideo"></a>
      <div className="container pl-3 pr-3">
        <div className="container pb-1"></div>
        <div className="text-center pb-2"></div>
        <h3 className="contentHeroTitle" style={{ color: `${textColor}` }}>
          {title}
        </h3>
        <h4 className="contentHeroSubTitle mt-0 mb-5" style={{ color: `${textColor}`, opacity: "0.6" }}>
          {renderedSubTitle}
        </h4>

        <div className="d-flex justify-content-center mt-1 mb-1"></div>

        <div className="container">
          <div className="row pb-2">
            <iframe
              srcDoc={youtubeReference}
              className="youtube-video"
              src="https://www.youtube.com/embed/k9hoBkeJLQc"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default HeroBottomInvestLandingWhyInvestSection
